.spinner-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: spin 1s linear infinite;
  border: 0.2em solid rgba(0, 0, 0, 0.3);
  border-top-color: rgb(0, 0, 0);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinnerText{

  color: #000000;
    font-size: 1.5rem;
  margin: 1rem;

}
