.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 90%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.modal-close {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: none;
  background: none;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

